<template>
  <div style='text-align: left'>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='400px' modal-append-to-body
               :title='dialogTitle' custom-class='kol-mapper-save-dialog' center>
      <el-form :model='formData' :inline='false' label-position='top'>
        <el-form-item label='红人：' required>
          <span v-show='!kolEdit'>{{ formData.nickname || '' }}</span>
          <artist-search v-show='kolEdit' @handleSelect='handleKOLSelect'
                         class='select-custom' :class='{editing:kolEdit}'
          />
          <i :class="!kolEdit?'el-icon-edit':'el-icon-check'"
             v-show='!isAdd' style='cursor: pointer;color: #ff3176;display: inline-block'
             @click="editLeader('kolEdit')"></i>
        </el-form-item>
        <el-form-item label='项目部&组：' required>
                  <span style='margin-right: 10px' v-show='!deptEdit' :title='`部门ID：${formData.dept_id}`'>
                    {{ formData.dept_name }}/{{ formData.group_name }}</span>
          <EcpDeptSelect v-if='deptEdit' class='select-custom' ref='ecp_dept'
                         @handleSelect='selectDept' :class='{editing:deptEdit}' />
          <i :class="!deptEdit?'el-icon-edit':'el-icon-check'"
             v-show='!isAdd' class='icon' @click="editLeader('deptEdit')"></i>
        </el-form-item>
        <template v-if='formData.dept_id'>
          <el-form-item :key='formData.dept_id' label='项目组长/副组长：'>
                  <span style='margin-right: 10px' v-show='!leaderEdit' :title='`钉钉用户ID：${formData.leader_id}`'>{{
                      formData.leader_name || ''
                    }}</span>
            <select-dept-user v-show='leaderEdit'
                              :value.sync='formData.leader_id' :label.sync='formData.leader_name'
                              :dpt-id='formData.group_id' :only-leader='true' :class='{editing:leaderEdit}'
            />
            <i :class="!leaderEdit?'el-icon-edit':'el-icon-check'"
               v-show='!isAdd' class='icon' @click="editLeader('leaderEdit')"></i>
          </el-form-item>
          <el-form-item :key='formData.dept_id' label='管理负责人：'>
            <span v-show='!m_leaderEdit' style='margin-right: 10px'>{{ formData.m_leader || '' }}</span>
            <select-dept-user v-show='m_leaderEdit'
                              :value.sync='formData.m_leader_id' :label.sync='formData.m_leader'
                              :dpt-id='formData.dept_id' :class='{editing:m_leaderEdit}'
            />
            <i :class="!m_leaderEdit?'el-icon-edit':'el-icon-check'"
               class='icon'
               v-show='!isAdd'
               @click="editLeader('m_leaderEdit')"></i>
          </el-form-item>
        </template>
      </el-form>
      <div slot='footer' style='text-align: center'>
        <el-button type='primary' size='medium' @click='onSubmit'>保存</el-button>
        <el-button size='medium' @click='close'>取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import UserSelect from '@/pages/commerce/components/UserSelect'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'
import SelectDeptUser from '../components/DeptUserSelect.vue'


export default {
  name: 'KolMapperCreateEdit',
  components: { SelectDeptUser, EcpDeptSelect, UserSelect, ArtistSearchCustom, ArtistSearch },
  computed: {
    dialogTitle() {
      return `${this.id ? '修改（ID：' + this.id + '）' : '添加'} 红人绩效关联`
    },
    isAdd() {
      return this.id ? false : true
    }
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        artist_id: null,
        nickname: null,
        dept_id: null,
        dept_name: null,
        group_id: '',
        group_name: '',
        leader_id: '',
        leader_name: '',
        m_leader_id: '',
        m_leader: ''
      },
      dept_group: {
        dpt_id: '',
        name: '',
        members: []
      },
      dept_manage: {
        dpt_id: '',
        name: '',
        members: []
      },
      m_leaderEdit: false,
      leaderEdit: false,
      deptEdit: false,
      kolEdit: false
    }
  },
  mounted() {
    this.onOpen()
    if (this.isAdd) {
      this.m_leaderEdit = true
      this.leaderEdit = true
      this.deptEdit = true
      this.kolEdit = true

    }
  },
  methods: {
    editLeader(type) {
      this[type] = this[type] ? false : true
    },

    selectedLeader(item) {
      this.formData.leader_id = item.value
      this.handleQuery()
    },
    onOpen() {
      this.loadingCommit = false
      if (this.isAdd) {
        this.formData = {
          artist_id: null,
          nickname: null,
          dept_id: null,
          dept_name: null,
          group_id: '',
          group_name: '',
          leader_id: '',
          leader_name: '',
          m_leader_id: '',
          m_leader: ''
        }
        this.dept_group = {
          dpt_id: '',
          name: '',
          members: []
        }
        this.dept_manage = {
          dpt_id: '',
          name: '',
          members: []
        }
      } else {
        this.getAInfo()
      }
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    async getAInfo() {
      //获取编辑对象的信息
      let { info } = await this.$api.getKolMapperInfo(this.id)
      this.formData = { ...info }
    },
    handleKOLSelect(row) {
      // if (this.isAdd) {
      this.formData.nickname = row.nickname
      this.formData.artist_id = row.id
      // }

    },
    async selectDept(arr) {
      //项目部2-组3
      if (arr && arr.length >= 2) {
        this.formData.dept_id = arr[arr.length - 2].dpt_id
        this.formData.group_id = arr[arr.length - 1].dpt_id
        // let deptNames = arr.map(_ => {
        //   return _.name
        // })
        // this.formData.dept_name = deptNames.join('/')
        this.formData.dept_name = arr[arr.length - 2].name
        this.formData.group_name = arr[arr.length - 1].name
        // 部门成员加载
        if (arr[arr.length - 2]) {
          let dept_m = arr[arr.length - 2]
          this.dept_manage = await this.getGroupMembers({ dpt_id: dept_m.dpt_id, name: dept_m.name })
        }
        //组员成员加载 0,1,2
        if (arr[arr.length - 1]) {
          let dept_g = arr[arr.length - 1]
          this.dept_group = await this.getGroupMembers({ dpt_id: dept_g.dpt_id, name: dept_g.name })
        }
      } else {
        this.formData.dept_id = ''
        this.formData.dept_name = ''
      }

    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {

      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },

    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.members.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    },
    encryptMobile(phone) {
      phone = '' + phone
      let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式

      return phone.replace(reg, '$1****$2')
    },
    async onSubmit() {
      if (!this.formData.artist_id) {
        this.msgWarn('红人必选')
        return false
      }
      // if (!this.formData.leader_id) {
      //   this.msgWarn('项目组长必选')
      //   return false
      // }
      if (!this.formData.dept_id) {
        this.msgWarn('项目组必选')
        return false
      }
      await this.storeData(this.formData)

    },
    async storeData(val) {
      let id = await this.$api.saveKolMapper(val)
      if (id) {
        this.msgSuccess('保存成功')
        // 模拟
        this.$emit('saved', this.formData)
        this.close()
      }
      // if (this.id)
      //   this.updateCache({ ...val })
      // else
      //   this.updateCache({ ...val, ...{ id: id ? id : new Date().getSeconds() } })
    }
    // updateCache(val) {
    //   let dataListStr = localStorage.getItem('kol_mappers')
    //   let dataList = dataListStr ? JSON.parse(dataListStr) : []
    //   let pIndex = dataList.findIndex(value => value.id === val.id)
    //   if (pIndex) {
    //     //更新数据
    //     dataList[pIndex] = { ...val }
    //   } else {
    //     dataList.push(val)
    //   }
    //
    //   localStorage.setItem('kol_mappers', JSON.stringify(dataList))
    // }
  },
  created() {

  }
}
</script>

<style scoped>
.card {
    line-height: 400px;
    width: 100%;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 5px;
}

.card-box {
    padding: 10px 20px;
    line-height: 400px;
    height: 200px;

    border: rgba(44, 39, 39, 0.5) 1px dashed;
    margin-bottom: 20px;
    font-weight: 400;
}

.icon {
    cursor: pointer;
    color: #ff3176;
    display: inline-block
}
</style>
<style lang='scss'>
.kol-mapper-save-dialog {
  border-radius: 20px !important;

  .el-form-item__label {
    font-weight: 700;
  }

  .el-select, .el-autocomplete {
    width: 100%;
  }

  .select-custom {
    width: 100%;
    display: inline-block;
    margin-right: 10px;
  }

  .editing.select-custom, .editing.el-select {
    width: calc(100% - 24px);
    margin-right: 10px;
  }
}
</style>
