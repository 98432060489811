<template>
  <el-select v-model='v' placeholder='请选择' :size='size' filterable clearable class='dept-user-select'>
    <el-option v-for='item in options' :key='item.userid' :value='item.userid' :label='item.name'>
      <span style='float: left'>
        {{ item.name }}
        <a v-if='item.position' style='padding: 1px 8px;background: #ffeef3;color: #fe346e;font-size: 12px;'>
          {{ item.position }}
        </a>
      </span>
      <span style='float: right;margin-left: 20px;'>{{ item.mobile }}</span>
    </el-option>
  </el-select>
</template>

<script>

/**
 * a. 在职用户
 * b. 指定部门
 */
export default {
  name: 'SelectDeptUser',
  props: ['value', 'size', 'dptId', 'onlyLeader'],
  data() {
    return {
      options: [],
      v: null
    }
  },
  watch: {
    v(_) {
      const tmp = this.options.find(option => {
        return option.userid === _
      })
      this.$emit('update:value', _)
      if (tmp) {
        this.$emit('update:label', tmp.name)
      }
    },
    dptId: {
      handler() {
        this.getOptions()
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    async getOptions() {
      this.options = []

      const params = { dpt_id: this.dptId, is_leave: 'N' }
      // if (this.onlyLeader) {
      //   params.isLeader = 1
      // }
      const list = await this.$api.optionsUser(params)
      list.map(_ => {
        _.mobile = _.mobile ? `${_.mobile.substr(0, 3)}****${_.mobile.substr(7)}` : ''
      })
      this.options = list
    }
  }
}
</script>

<style lang='scss'>

</style>
